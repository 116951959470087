<template>
  <div>
    <el-row  :gutter="20" v-bind:style="{ 'background-image': 'url(' + loginbgimage + ')','background-repeat': 'no-repeat','height':'1000px'}">
     <el-col :span="4" :offset="15" style="opacity: 1;margin-top: 15%">
<!--       <el-card style="background-color: #eaeaea;width: 400px;height: 550px">-->
<!--         <el-image style="width: 150px; height: 80px; margin-left: 30%;margin-top: 5%;" src="/static/logo222.png"></el-image>-->
<!--         <el-divider class="login_divider"> <i style="background-color: #eaeaea;"><strong>管理员登录</strong></i></el-divider>-->
            <el-form :model="inputForm" status-icon ref="inputForm" :rules="rules" label-position="left" label-width="80px">
              <el-form-item label="" prop="email">
                <h2 style="color: #d9cece;text-align: center;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;管理员登录</h2>
                <el-input v-model="inputForm.email" prefix-icon="el-icon-user" placeholder="请输入邮箱" class="loginInput" style="width: 250px;margin-top: 10px;" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input type="password"  prefix-icon="el-icon-view" placeholder="请输入密码" v-model="inputForm.password" class="loginInput" style="width: 250px;" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="" prop="captcha">
                <div @mouseleave="mouseLeaveOne"  @mouseover="mouseOverOne">
                  <el-input prefix-icon="el-icon-refresh-left" v-model.number="inputForm.captcha" style="width: 250px;" class="loginInput" placeholder="请输入验证码"   autocomplete="off"></el-input>
                  <el-image v-show="captchaShow" :src="captchaUrl" @click="refreshCaptcha"></el-image>
                </div>
             </el-form-item>
              <el-form-item>
                <el-button type="warning" @click="submitForm('inputForm')" style="width: 250px">登录</el-button>
<!--                <el-button @click="resetForm('inputForm')">重置</el-button>-->
              </el-form-item>
            </el-form>
<!--        </el-card>-->
     </el-col>
  </el-row>

  </div>
</template>
<script>
import {reqManagerLogin} from '@/api/utadmin/manager_login.api'
import Vue from "vue";
export default {
  data() {
    var checkEmail = (rule, value, callback) => {//邮箱校验
            const mailReg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
              return callback(new Error('邮箱不能为空'))
            }
            setTimeout(() => {
              if (mailReg.test(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的邮箱格式'))
              }
            }, 100)
          }
    return {
      captchaShow:false,
      captchaUrl: this.GLOBAL.SERVER_HOST+'/utuser/utcaptcha',
      inputForm: {
        email: '',
        password: '',
      },
      loginbgimage: '/static/loginpage.png',
      rules: {
        email: [
          {required: true, validator: checkEmail, trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 30, message: '最少6位', trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {type: 'number', message: '请输入数字计算结果', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    mouseOverOne() {
        this.captchaShow=true;
      },
      // 鼠标移出
      mouseLeaveOne() {
         this.captchaShow=false;
      },
      refreshCaptcha(){
          const randomNumber = Math.round(Math.random() * 1000000)
          this.captchaUrl = this.GLOBAL.SERVER_HOST+'/utuser/utcaptcha?' + randomNumber
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              //alert('submit!');
            this.reqLoginFn();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //loginImpl
      reqLoginFn: async function () {
          // let reqForm = Object.assign(this.inputForm, {});
          const AES_KEY=this.CryptoJS.enc.Utf8.parse('LaF7oQt50q0DbSDh');
          const AES_IV=this.CryptoJS.enc.Utf8.parse('LaF7oQt5LaF7oQt5');
          const email_encrypt = this.$CryptoJS.AES.encrypt(this.inputForm.email, AES_KEY, { iv: AES_IV }).toString();
          const password_encrypt = this.$CryptoJS.AES.encrypt(this.inputForm.password, AES_KEY, { iv: AES_IV}).toString();
          let reqForm ={
            captcha: this.inputForm.captcha,
            email: email_encrypt,
            password: password_encrypt,
          }
          let res = await reqManagerLogin(reqForm);
          if (res){
            if (res.info == '') {
              this.$cookies.set('managername', this.inputForm.email,'15d' );
              this.$router.push({path: res.redirect, query: {t: Date.now()}});
            } else {
              Vue.prototype.$notify.error({
                        title: '错误',
                        message: res.info
                    });
              this.refreshCaptcha();
            }
          }
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>
<style>
.el-row {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}
.loginInput .el-input__inner {
  //border: none;
  border:  1px solid #AA9955;;
  background-color: rgba(255, 255, 255, 0);
  color: #17a1e5;
  font-size: 15px;
}
</style>
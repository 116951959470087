<template>
<div>
  <el-row  :gutter="20" v-bind:style="{ 'background-image': 'url(' + loginbgimage + ')','background-repeat': 'no-repeat','height':'1000px'}">
     <el-col :span="4" :offset="15" style="opacity: 1;margin-top: 15%">
<!--       <el-card style="background-color: #eaeaea ;width: 400px;height: 550px">-->

<!--          <el-divider class="login_divider"> <i style="background-color: #eaeaea;"><strong>用户登录</strong></i></el-divider>-->
          <el-form :model="loginForm" status-icon ref="loginForm" :rules="loginRules" label-position="right"  label-width="80px">
<!--           <el-image style="width: 150px; height: 80px; margin-left: 30%;margin-top: 5%;" src="/static/logo111.png"></el-image><br/><br/>-->

            <el-form-item label="" prop="email">
              <h2 style="color: #d9cece;text-align: center;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用户登录</h2>
              <el-input prefix-icon="el-icon-user" v-model="loginForm.email" autocomplete="off" class="loginInput" placeholder="请输入邮箱" style="width: 250px;margin-top: 10px"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input prefix-icon="el-icon-view" type="password" v-model="loginForm.password" class="loginInput" placeholder="请输入密码" autocomplete="off" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="" prop="captcha">
              <div @mouseleave="mouseLeaveOne"  @mouseover="mouseOverOne">
                <el-input prefix-icon="el-icon-refresh-left"  v-model.number="loginForm.captcha" style="width: 250px;" class="loginInput" placeholder="请输入验证码"  autocomplete="off"></el-input>

              <el-image v-show="captchaShow" :src="captchaUrl" @click="refreshCaptcha" style="width: 200px;"></el-image></div>
            </el-form-item>
            <el-form-item>
              <el-button type="warning" @click="submitLoginForm('loginForm')" style="width: 250px">登录</el-button>
<!--              <el-button @click="resetForm('loginForm')">重置</el-button>-->
            </el-form-item>

            <el-form-item><el-link type="info" @click="forgetFormShow=true" style="text-decoration:underline">忘记密码</el-link>
<!--              |<el-link type="info" @click="regFormShow=true">注册账号</el-link>--> </el-form-item>
          </el-form>
<!--        </el-card>-->
     </el-col>
  </el-row>

    <el-dialog title="账号注册" :visible.sync="regFormShow"  width="25%" >
      <el-form :model="regForm" status-icon :rules="regRules" ref="regForm" label-width="100px" class="demo-regForm">
            <el-form-item label="邮箱" prop="email">
              <el-input type="email" v-model="regForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="regForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="regForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input v-model.number="regForm.captcha" style="width: 200px;" placeholder="请输入数字运算结果"  autocomplete="off"></el-input>
              <el-image :src="captchaUrl" @click="refreshCaptcha"></el-image>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitRegForm('regForm')">提交</el-button>
              <el-button @click="resetForm('regForm')">重置</el-button>
            </el-form-item>
          </el-form>
    </el-dialog>

    <el-dialog title="密码找回" :visible.sync="forgetFormShow"  width="25%" >
      <el-form :model="forgetForm" status-icon :rules="forgetRules" ref="forgetForm" label-width="100px" class="demo-regForm">
            <el-form-item label="邮箱" prop="email">
              <el-input type="email" v-model="forgetForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input v-model.number="forgetForm.captcha" style="width: 200px;" placeholder="请输入数字运算结果"  autocomplete="off"></el-input>
              <el-image :src="captchaUrl" @click="refreshCaptcha"></el-image>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForgetForm('forgetForm')">提交</el-button>
              <el-button @click="resetForm('forgetForm')">重置</el-button>
            </el-form-item>
          </el-form>
    </el-dialog>

    <el-dialog title="密码修改" :visible.sync="modifyFormShow"  width="25%" >
      <el-form :model="modifyForm" status-icon :rules="modifyRules" ref="modifyForm" label-width="100px" class="demo-regForm">
            <el-form-item label="新密码" prop="password">
              <el-input type="password" v-model="modifyForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="modifyForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input v-model.number="modifyForm.captcha" style="width: 200px;" placeholder="请输入数字运算结果"  autocomplete="off"></el-input>
              <el-image :src="captchaUrl" @click="refreshCaptcha"></el-image>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitmodifyForm('modifyForm')">提交</el-button>
              <el-button @click="resetForm('modifyForm')">重置</el-button>
            </el-form-item>
          </el-form>
    </el-dialog>


  </div>
</template>
<script>
import {reqAckEmail, reqResetPwdACK, reqResetPwdEmail, reqUserLogin, reqUserReg} from '@/api/utuser/user_login.api'
import Vue from "vue";
import { Loading } from 'element-ui';
export default {
  data() {
    var checkEmail = (rule, value, callback) => {//邮箱校验
            const mailReg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
              return callback(new Error('邮箱不能为空'))
            }
            setTimeout(() => {
              if (mailReg.test(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的邮箱格式'))
              }
            }, 100)
          };
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.regForm.checkPass !== '') {
            this.$refs.regForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.regForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      var validateModifyPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.modifyForm.checkPass !== '') {
            this.$refs.modifyForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validateModifyPass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.modifyForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      captchaShow:false,
      captchaUrl: this.GLOBAL.SERVER_HOST+'/utuser/utcaptcha',
      loginbgimage: '/static/loginpage.png',
      regFormShow:false,
      regForm:{
        email: '',
        password: ""
      },
      forgetFormShow:false,
      forgetForm:{
        email: '',
        captcha: '',
      },
      modifyFormShow:false,
      modifyForm:{
        password: '',
        pwd_reset_token:'',
        captcha: '',
      },
      loginForm: {
        email: '',
        password: '',
        // captcha: ,
      },
      loginRules: {
        email: [
          {required: true, validator: checkEmail, trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 50, message: '最少6位', trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {type: 'number', message: '请输入数字计算结果', trigger: 'blur'}
        ],
      },
      regRules: {
        email: [
          {required: true, validator: checkEmail, trigger: 'blur'},
        ],
        password: [
          {required: true, validator: validatePass, trigger: 'blur'},
          {min: 6, max: 30, message: '最少6位', trigger: 'blur'}
        ],
        checkPass: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {type: 'number', message: '请输入数字计算结果', trigger: 'blur'}
        ],
      },
      forgetRules: {
        email: [
          {required: true, validator: checkEmail, trigger: 'blur'},
        ],
        captcha: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {type: 'number', message: '请输入数字计算结果', trigger: 'blur'}
        ],
      },
      modifyRules: {
        password: [
          {required: true, validator: validateModifyPass, trigger: 'blur'},
          {min: 6, max: 50, message: '最少6位', trigger: 'blur'}
        ],
        checkPass: [
          {required: true, validator: validateModifyPass2, trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {type: 'number', message: '请输入数字计算结果', trigger: 'blur'}
        ],
      },
      token:this.$route.query.token,
      pwd_reset_token:this.$route.query.pwd_reset_token,
    }
  },
  methods: {
      mouseOverOne() {
        this.captchaShow=true;
      },
      // 鼠标移出
      mouseLeaveOne() {
         this.captchaShow=false;
      },
      refreshCaptcha(){
          const randomNumber = Math.round(Math.random() * 1000000)
          this.captchaUrl = this.GLOBAL.SERVER_HOST+'/utuser/utcaptcha?' + randomNumber
      },
      submitLoginForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              //alert('submit!');
            this.reqLoginFn();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitmodifyForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.reqModifyFn();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      reqModifyFn: async function () {
        // let reqForm = Object.assign(this.loginForm, {});
        const AES_KEY=this.CryptoJS.enc.Utf8.parse('LaF7oQt50q0DbSDh');
        const AES_IV=this.CryptoJS.enc.Utf8.parse('LaF7oQt5LaF7oQt5');
        const password_encrypt = this.$CryptoJS.AES.encrypt(this.modifyForm.password, AES_KEY, { iv: AES_IV}).toString();
        let reqForm ={
          captcha: this.modifyForm.captcha,
          password: password_encrypt,
          pwd_reset_token: this.pwd_reset_token
        }
        let res = await reqResetPwdACK(reqForm);
        if (res){
          if (res.info == '') {
             this.$message({
                message: '重置成功，请用新密码登录。',
                type: 'success'
              });
             this.modifyFormShow=false;
          } else {
            Vue.prototype.$notify.error({
                        title: '错误',
                        message: res.info
                    });
            this.refreshCaptcha();
          }

        }
      },
      //loginImpl
      reqLoginFn: async function () {
        // let reqForm = Object.assign(this.loginForm, {});
        const AES_KEY=this.CryptoJS.enc.Utf8.parse('LaF7oQt50q0DbSDh');
        const AES_IV=this.CryptoJS.enc.Utf8.parse('LaF7oQt5LaF7oQt5');
        const email_encrypt = this.$CryptoJS.AES.encrypt(this.loginForm.email, AES_KEY, { iv: AES_IV }).toString();
        const password_encrypt = this.$CryptoJS.AES.encrypt(this.loginForm.password, AES_KEY, { iv: AES_IV}).toString();
        let reqForm ={
          captcha: this.loginForm.captcha,
          email: email_encrypt,
          password: password_encrypt,
        }
        let res = await reqUserLogin(reqForm);
        if (res){
          if (res.info == '') {
            //登录成功  用户名写入cookie 用于前端展示
            this.$cookies.set('username', this.loginForm.email, {expires: "15d"});
            this.$router.push({
              path: res.redirect, query: {t: Date.now()},
            });
          } else {
            Vue.prototype.$notify.error({
                        title: '错误',
                        message: res.info
                    });
            this.refreshCaptcha();
          }
        }
      },
      submitRegForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              //alert('submit!');
            this.reqRegFn();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitForgetForm(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
                //alert('submit!');
              this.reqForgetFn();
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
      //loginImpl
      reqForgetFn: async function () {
        let forgetForm = Object.assign(this.forgetForm, {});
        let loadingInstance1 = Loading.service({ fullscreen: true });
        let res = await reqResetPwdEmail(forgetForm);
        if (res){
          if (res.info == '') {
             this.$message({
                message: '重置邮件已发送到邮箱中,请登录邮箱查看。',
                type: 'success'
              });
             this.forgetFormShow=false;
          } else {
            this.$message.error(res.info);
            this.refreshCaptcha();
          }
        }
        loadingInstance1.close();
      },
      //regImpl
      reqRegFn: async function () {
        let reqForm = Object.assign(this.regForm, {});
        let res = await reqUserReg(reqForm);
        if (res){
          if (res.status == 'success') {
             this.$message({
                message: '注册成功,请到邮箱中确认方可登录.',
                type: 'success'
              });
             this.regFormShow=false;
          } else {
            this.$message.error(res.info);
          }
        }
      },
      reqAckEmailFn: async function () {
        if(this.token){
            let res = await reqAckEmail({token: this.token});
            if (res) {
              this.$message({
                message: '验证成功,请输入账号密码登录.',
                type: 'success'
              });
            }
        }
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
  mounted(){
    this.reqAckEmailFn(this.token);
    if(this.pwd_reset_token){
      this.modifyFormShow=true;
    }
  }
}
</script>
<style>
.el-row {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

}

.loginInput .el-input__inner {
  //border: none;
  border:  1px solid #AA9955;;
  background-color: rgba(255, 255, 255, 0);
  color: #17a1e5;
  font-size: 15px;
}
</style>
import Vue from 'vue'
import Router from 'vue-router'
import Managerlogin from "@/components/utadmin/page/ManagerLogin.vue";
import MemberLogin from "@/components/utuser/page/MemberLogin.vue";
Vue.use(Router)

const originalPush = Router.prototype.push
//修改原型对象中的push方法 //解决vue路由重复导航错误
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/userlogin',
      name: 'userlogin',
      component: MemberLogin
    },
    {
      path: '/utmanagerloginmanagerlogin',
      name: 'Managerlogin',
      component: Managerlogin
    },
    {
      path: '/utadmin/',
      component: () => import('@/components/utadmin/common/Home.vue'),
      meta: { title: 'ManagerPage' },
      children: [
                {
                    name:"member",
                    path: '/member',
                    component: () => import('@/components/utadmin/page/user/member.vue'),
                    meta: { title: 'member页' }
                },
                {
                    name:"manager",
                    path: '/manager',
                    component: () => import('@/components/utadmin/page/user/manager.vue'),
                    meta: { title: 'manager' }
                },
                {
                    name:"pointrecord",
                    path: '/pointrecord',
                    component: () => import('@/components/utadmin/page/point/PointRecord.vue'),
                    meta: { title: 'pointrecord' }
                },
                {
                    name:"systemlog",
                    path: '/systemlog',
                    component: () => import('@/components/utadmin/page/system/SystemLog.vue'),
                    meta: { title: 'systemlog' }
                },
                {
                    name:"giftmg",
                    path: '/giftmg',
                    component: () => import('@/components/utadmin/page/gift/GiftMg.vue'),
                    meta: { title: 'giftmg' }
                },
                {
                    name:"giftrecordmg",
                    path: '/grecordmg',
                    component: () => import('@/components/utadmin/page/gift/GiftOrder.vue'),
                    meta: { title: 'giftrecordmg' }
                },
                {
                    name:"activitymg",
                    path: '/activitymg',
                    component: () => import('@/components/utadmin/page/activity/ActivityMg.vue'),
                    meta: { title: 'activitymg' }
                },
                {
                    name:"activityrpt",
                    path: '/activityrpt',
                    component: () => import('@/components/utadmin/page/activity/ActivityRpt.vue'),
                    meta: { title: 'activityrpt' }
                },
                {
                    name:"security_advisory_manage",
                    path: '/security_advisory_manage',
                    component: () => import('@/components/utadmin/page/security_advisory/SecurityAdivisoryManage.vue'),
                    meta: { title: 'manager' }
                },
                {
                  name:"platform_advisory_manage",
                  path: '/platform_advisory_manage',
                  component: () => import('@/components/utadmin/page/platform_advisory/PlatformAdivisoryManage.vue'),
                  meta: { title: 'platform_advisory_manage' }
                },
                {
                  name:"vulnerability_manage",
                  path: '/vulnerability_manage',
                  component: () => import('@/components/utadmin/page/vulnerability/Vulnerability.vue'),
                  meta: { title: 'vulnerability_manage' }
                },
                {
                    name:"index_manage",
                    path: '/index_manage',
                    component: () => import('@/components/utadmin/page/index/Index.vue'),
                    meta: { title: 'index_manage' }
                }
               ]
    },
    {
      path: '/uthome/',
      component: () => import('@/components/uthome/common/Home.vue'),
      meta: { title: 'HomePage' },
      children: [{
                    name:"index",
                    path: '/index',
                    component: () => import('@/components/uthome/page/indexpage/Index.vue'),
                    meta: { title: '首页' }
                },
                {
                    name:"activity",
                    path: '/activity',
                    component: () => import('@/components/uthome/page/activity/Activity.vue'),
                    meta: { title: 'activity' }
                },{
                    name:"activity_detail",
                    path: '/activity_detail',
                    component: () => import('@/components/uthome/page/activity/ActivityDetail.vue'),
                    meta: { title: 'activity_detail' }
                },
                {
                    name:"gift",
                    path: '/gift',
                    component: () => import('@/components/uthome/page/gift/Gift.vue'),
                    meta: { title: 'gift' }
                },{
                    name:"gift_detail",
                    path: '/gift_detail',
                    component: () => import('@/components/uthome/page/gift/GiftDetail.vue'),
                    meta: { title: 'gift_detail' }
                },{
                    name:"security_advisory",
                    path: '/security_advisory',
                    component: () => import('@/components/uthome/page/security_advisory/SecurityAdivisoryList.vue'),
                    meta: { title: 'security_advisory' }
                },
                {
                    name:"security_advisory_detail",
                    path: '/security_advisory_detail',
                    component: () => import('@/components/uthome/page/security_advisory/SecurityAdivisoryDetail.vue'),
                    meta: { title: 'security_advisory_detail' }
                },
                {
                    name:"platform_advisory_detail",
                    path: '/platform_advisory_detail',
                    component: () => import('@/components/uthome/page/platform_advisory/PlatformAdivisoryDetail.vue'),
                    meta: { title: 'platform_advisory_detail' }
                },
                {
                    name:"platform_advisory",
                    path: '/platform_advisory',
                    component: () => import('@/components/uthome/page/platform_advisory/PlatformAdivisoryList.vue'),
                    meta: { title: 'platform_advisory' }
                },
                {
                    name:"vulnerability_submit",
                    path: '/vulnerability_submit',
                    component: () => import('@/components/uthome/page/vulnerability/VulnerabilitySubmit.vue'),
                    meta: { title: 'vulnerability_submit' }
                },
                {
                    name:"leaderboard",
                    path: '/leaderboard',
                    component: () => import('@/components/uthome/page/leaderboard/Leaderboard.vue'),
                    meta: { title: 'leaderboard' }
                },
                {
                    name:"lab",
                    path: '/lab',
                    component: () => import('@/components/uthome/page/lab/LabList.vue'),
                    meta: { title: 'leaderboard' }
                },
                // {
                //     name:"labscan",
                //     path: '/labscan',
                //     component: () => import('@/components/uthome/page/lab/LabScan.vue'),
                //     meta: { title: 'leaderboard' }
                // },
                {
                    name:"labcooperation",
                    path: '/labcooperation',
                    component: () => import('@/components/uthome/page/lab/LabCooperation.vue'),
                    meta: { title: 'leaderboard' }
                },
                {
                    name:"SecPractices",
                    path: '/SecPractices',
                    component: () => import('@/components/uthome/page/lab/SecPractices.vue'),
                    meta: { title: 'SecPractices' }
                },
               ]
    },
    {
      path: '/utuser/',
      component: () => import('@/components/utuser/common/Home.vue'),
      meta: { title: '个人主页' },
      children: [
                {
                    name:"usercenter",
                    path: '/usercenter',
                    component: () => import('@/components/utuser/page/UserCenter.vue'),
                    meta: { title: 'usercenter' }
                },
                {
                    name:"uservul",
                    path: '/uservul',
                    component: () => import('@/components/utuser/page/UserVul.vue'),
                    meta: { title: 'uservul' }
                },
                {
                    name:"usercoin",
                    path: '/usercoin',
                    component: () => import('@/components/utuser/page/UserCoin.vue'),
                    meta: { title: 'usercoin' }
                },
                {
                    name:"usergift",
                    path: '/usergift',
                    component: () => import('@/components/utuser/page/UserGift.vue'),
                    meta: { title: 'usergift' }
                },
                {
                    name:"usermessage",
                    path: '/usermessage',
                    component: () => import('@/components/utuser/page/UserMessage.vue'),
                    meta: { title: 'usermessage' }
                }
               ]
    },
   // 404 页面
   {
      path: "/404",
      name: "NotFound",
      component: () => import("@/components/404.vue"),
      hidden: true,
      meta: {title: "404"},
  },
  // 当什么都没有匹配到的时候，重定向页面到 404 页面
  {path: "/:pathMatch(.*)", redirect: "/404", name: "notMatch", hidden: true},
  ]
})



import { request } from '@/utils/request'

export const reqUserLogin = async(query) => {
    return await request({
        url: '/utuser/utuserloginhdxiwhjdwobzuxsq',
        method: 'post',
        data: query
    });
};

export const reqUserReg = async(query) => {
    return await request({
        url: '/utuser/utuserreg',
        method: 'post',
        data: query
    });
};

export const reqResetPwdEmail = async(query) => {
    return await request({
        url: '/utuser/reset_pwd_email',
        method: 'post',
        data: query
    });
};

export const reqResetPwdACK = async(query) => {
    return await request({
        url: '/utuser/reset_pwd_ack',
        method: 'post',
        data: query
    });
};

export const reqModifyPwd = async(query) => {
    return await request({
        url: '/utuser/modify_pwd',
        method: 'post',
        data: query
    });
};

export const reqUserLoginStatus = async(query) => {
    return await request({
        url: '/uthome/user_login_status',
        method: 'post',
        data: query
    });
};

export const reqLoginOut = async(query) => {
    return await request({
        url: '/utuser/loginout',
        method: 'post',
        data: query
    });
};

export const reqAckEmail = async(query) => {
    return await request({
        url: '/utuser/ackemail',
        method: 'post',
        data: query
    });
};
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
    @import "./assets/css/main.css";
     /* 全局滚动条的设置 */
    ::-webkit-scrollbar {
        width: 6px;
        height: 10px;
        /* display: none; */
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background:#c1b9b9;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background:transparent;
    }
    ::-webkit-scrollbar-thumb:hover {
        border-radius: 6px;
        background:  #776f6f;
    }
</style>
<script>
    //全局变量定义
    // const SERVER_HOST='http://10.0.34.91:8000'  //后台服务器地址 当前后端分离开始时 写server地址
    const SERVER_HOST=''  //生产环境或者测试服务器 直接留空
    //富文本编辑器工具栏显示的按钮
    const toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
      ["blockquote", "code-block"], // 引用  代码块
      // [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
      [{ 'header': 1 }, { 'header': 2 }],
      // [{ script: "sub" }, { script: "super" }], // 上标/下标
      // [{ indent: "-1" }, { indent: "+1" }],
      // [{ size: ['small', false, 'large', 'huge']}], // 配置字号

      // [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
      [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
      // [{ font: []}], //显示字体选择
      [{ align: [] }], // 对齐方式
      // ["clean"], // 清除文本格式
      ['link', 'image']
      // ['link', 'image', 'video'], // 链接、图片、视频
    ];

    // 自己配置消毒规则
    const sanitizeHtmlOptiosn = {
      allowedTags: [
        "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
        "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
        "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
        "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
        "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
        "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
        "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "font",
        "img",
      ],
      disallowedTagsMode: 'discard',
      allowedAttributes: {
        font: [ 'color' ],
        // a: [ 'href', 'name', 'target' ],
        img: [ 'src'  ], //'srcset', 'alt', 'title', 'width', 'height', 'loading',
        // video: ['preload', 'controls', 'src'],
        // audio: ['src'],
        '*': ['style', 'class', 'id', 'data-kc'],
      },
      selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
      allowedSchemes: [ 'http', 'https',],
      allowedSchemesByTag: {},
      allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
      allowProtocolRelative: true,
      enforceHtmlBoundary: false
    }

    export default {
        toolbarOptions,
        sanitizeHtmlOptiosn,
        SERVER_HOST
    }
</script>
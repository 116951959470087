import Vue from 'vue';

// import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/css/common.css'

import ElementUI from 'element-ui';   //引入element-ui
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
Vue.use(ElementUI);


import router from './router/index'  //引入vue-router
import VueRouter from 'vue-router'
Vue.config.productionTip = false
Vue.use(VueRouter);

// import animate from "animate.css"
// Vue.use(animate);

import VueVideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VueVideoPlayer)

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'highlight.js/styles/monokai-sublime.css';
import 'highlight.js/styles/darcula.css';
Vue.use(VueQuillEditor)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

//全局变量定义
import global from './components/GlobalVar.vue'
Vue.prototype.GLOBAL = global;

new Vue({
  el: '#app',
  router,
  render: h => h(App)
});